/**=====================
    10.  ICON CSS Start
==========================**/
.icons-page {
    .notify-alert {
        width: auto !important;
        min-width: 170px;
        padding: 7px 40px 7px 30px;
        letter-spacing: 1.5px;
        font-weight: 600;
        background-color: $primary-color;
        button {
            top: 3px !important;
            color: #fff;
        }
    }
}
.icon-lists {
    div {
        cursor: pointer;
        line-height: 60px;
        white-space: nowrap;
        border-radius: 4px;
        font-family: $font-arial;
        display: flex;
        align-items: center;

        &:hover {
            background-color: $auth-bg-color;
        }
        i {
            margin: 0 15px 0 10px;
            font-size: 30px;
            color: $dark-gray;
        }
    }
    &.whether-icon {
        svg {
            height: 50px;
            width: 50px;
        }
    }
}
.flag-icons {
    margin-bottom: -15px;
    margin-top: -15px;
    div {
        line-height: 1;
        word-wrap: break-word;
        overflow: auto;
        font-family: $font-arial;
        .flag-icon {
            height: 50px;
            width: 50px;
        }
        .media {
            padding-top: 15px;
            padding-bottom: 15px;
        }
        .media-body {
            h6 {
                margin-bottom: 0;
                font-size: 14px;
                max-width: 100%;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
            }
            h5 {
                background-color: $primary-color;
                display: inline-block;
                padding: 3px 5px;
                font-size: 11px;
                border-radius: 3px;
                margin-bottom: 5px;
                color: $white;
            }
        }
    }
}
.icon-hover-bottom {
    background-color: $light;
    width: 100%;
    bottom: 0;
    left: 0;
    box-shadow: 0px 0px 1px 1px $light-color;
    z-index: 999;
    .icon-popup {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        .close-icon {
            position: absolute;
            right: 10px;
            top: 10px;
            font-size: 20px;
            color: $gray-60;
            cursor: pointer;
        }
        padding: 20px;
        > div {
            display: inline-block;
            font-family: $font-arial;
            .flag-icon {
                width: 60px;
                height: 60px;
            }
        }
        .icon-class {
            margin: 0 20px;
            cursor: pointer;
            font-family: $font-arial;
        }
    }
    .icon-title {
        display: block;
        color: $dark-color;
        text-transform: uppercase;
        font-weight: 500;
        font-family: $font-arial;
    }
    .form-group {
        input {
            min-width: 300px;
            background-color: $white;
        }
    }
}
.fa-fa-icon-show-div,
.icons-show-div {
    display: none;
}
.icon-first {
    i {
        color: $dark-gray;
    }
}
/**=====================
    10.  ICON CSS Ends
==========================**/
