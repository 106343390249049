/**=====================
     54. Tour  CSS Start
==========================**/

.introjs-overlay {
    position: absolute;
    box-sizing: content-box;
    z-index: 99999;
    background-color: $black;
    opacity: 0;
    background: -moz-radial-gradient(
        center,
        ellipse farthest-corner,
        rgba(0, 0, 0, 0.9) 0,
        rgba(0, 0, 0, 0.9) 100%
    );
    background: -webkit-gradient(
        radial,
        center center,
        0px,
        center center,
        100%,
        color-stop(0%, rgba(0, 0, 0, 0.4)),
        color-stop(100%, rgba(0, 0, 0, 0.9))
    );
    background: -webkit-radial-gradient(
        center,
        ellipse farthest-corner,
        rgba(0, 0, 0, 0.9) 0,
        rgba(0, 0, 0, 0.9) 100%
    );
    background: -o-radial-gradient(
        center,
        ellipse farthest-corner,
        rgba(0, 0, 0, 0.9) 0,
        rgba(0, 0, 0, 0.9) 100%
    );
    background: -ms-radial-gradient(
        center,
        ellipse farthest-corner,
        rgba(0, 0, 0, 0.9) 0,
        rgba(0, 0, 0, 0.9) 100%
    );
    background: radial-gradient(
        center,
        ellipse farthest-corner,
        rgba(0, 0, 0, 0.9) 0,
        rgba(0, 0, 0, 0.9) 100%
    );
    filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#66000000',endColorstr='#e6000000',GradientType=1)";
    -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=50)';
    filter: alpha(opacity = 50);
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
}
.introjs-fixParent {
    z-index: auto !important;
    opacity: 1 !important;
    -webkit-transform: none !important;
    -moz-transform: none !important;
    -ms-transform: none !important;
    -o-transform: none !important;
    transform: none !important;
}
.introjs-showElement {
    z-index: 9999999 !important;
}
tr.introjs-showElement > {
    td,
    th {
        z-index: 9999999 !important;
    }
}
.introjs-disableInteraction {
    z-index: 99999999 !important;
    position: absolute;
    background-color: $white;
    opacity: 0;
    filter: alpha(opacity = 0);
}
.introjs-relativePosition {
    position: relative;
}
tr.introjs-showElement > {
    td,
    th {
        position: relative;
    }
}
.introjs-helperLayer {
    box-sizing: content-box;
    position: absolute;
    z-index: 9999998;
    background-color: $white;
    background-color: rgba(255, 255, 255, 0.9);
    border: 1px solid $theme-body-sub-title-color;
    border: 1px solid rgba(0, 0, 0, 0.5);
    border-radius: 4px;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.4);
    transition: all 0.3s ease-out;
}
.introjs-tooltipReferenceLayer {
    box-sizing: content-box;
    position: absolute;
    visibility: hidden;
    z-index: 100000000;
    background-color: transparent;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
}
.introjs-helperLayer * {
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    -ms-box-sizing: content-box;
    -o-box-sizing: content-box;
    box-sizing: content-box;
    &:before,
    &:after {
        -webkit-box-sizing: content-box;
        -moz-box-sizing: content-box;
        -ms-box-sizing: content-box;
        -o-box-sizing: content-box;
        box-sizing: content-box;
    }
}
.introjs-helperNumberLayer {
    box-sizing: content-box;
    position: absolute;
    visibility: visible;
    top: -16px;
    left: -16px;
    z-index: 9999999999 !important;
    padding: 2px;
    font-family: Arial, verdana, tahoma;
    font-size: 13px;
    font-weight: bold;
    color: $white;
    text-align: center;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
    background: $danger-color;
    /* Old browsers */
    background: -webkit-linear-gradient(
        top,
        $flower-pink 0%,
        $flower-pink 100%
    );
    /* Chrome10+,Safari5.1+ */
    background: -webkit-gradient(
        linear,
        left top,
        left bottom,
        color-stop(0%, $flower-pink),
        color-stop(100%, $danger-color)
    );
    /* Chrome,Safari4+ */
    background: -moz-linear-gradient(top, $flower-pink 0%, $danger-color 100%);
    /* FF3.6+ */
    background: -ms-linear-gradient(top, $flower-pink 0%, $danger-color 100%);
    /* IE10+ */
    background: -o-linear-gradient(top, $flower-pink 0%, $danger-color 100%);
    /* Opera 11.10+ */
    background: linear-gradient(to bottom, $flower-pink 0%, $danger-color 100%);
    /* W3C */
    background: linear-gradient(
        to bottom,
        $primary-color 0%,
        $primary-color 100%
    );
    width: 20px;
    height: 20px;
    line-height: 20px;
    border: 3px solid $white;
    border-radius: 50%;
    filter: 'progid:DXImageTransform.Microsoft.Shadow(direction=135, strength=2, color=ff0000)';
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);
}
.introjs-arrow {
    border: 12px solid $white;
    content: '';
    position: absolute;
    &.top {
        top: -20px;
        border-top-color: transparent;
        border-right-color: transparent;
        border-bottom-color: $primary-color;
        border-left-color: transparent;
    }
    &.top-right {
        top: -10px;
        right: 10px;
        border-top-color: transparent;
        border-right-color: transparent;
        border-bottom-color: $primary-color;
        border-left-color: transparent;
    }
    &.top-middle {
        top: -10px;
        left: 50%;
        margin-left: -5px;
        border-top-color: transparent;
        border-right-color: transparent;
        border-bottom-color: $primary-color;
        border-left-color: transparent;
    }
    &.right {
        right: -30px;
        top: 10px;
        border-top-color: transparent;
        border-right-color: transparent;
        border-bottom-color: transparent;
        border-left-color: $primary-color;
    }
    &.right-bottom {
        bottom: 10px;
        right: -30px;
        border-top-color: transparent;
        border-right-color: transparent;
        border-bottom-color: transparent;
        border-left-color: $primary-color;
    }
    &.bottom {
        bottom: -20px;
        border-top-color: $primary-color;
        border-right-color: transparent;
        border-bottom-color: transparent;
        border-left-color: transparent;
    }
    &.left {
        left: -30px;
        top: 10px;
        border-top-color: transparent;
        border-right-color: $primary-color;
        border-bottom-color: transparent;
        border-left-color: transparent;
    }
    &.left-bottom {
        left: -30px;
        bottom: 10px;
        border-top-color: transparent;
        border-right-color: $primary-color;
        border-bottom-color: transparent;
        border-left-color: transparent;
    }
}

.introjs-tooltip {
    box-sizing: content-box;
    position: absolute;
    visibility: visible;
    padding: 15px;
    background-color: $primary-color;
    min-width: 250px;
    max-width: 300px;
    border-radius: 3px;
    box-shadow: 0 1px 10px rgba(0, 0, 0, 0.4);
    transition: opacity 0.1s ease-out;
    border: 0;
    color: $white;
}

.introjs-tooltipbuttons {
    text-align: right;
    white-space: nowrap;
}

.introjs-button {
    box-sizing: content-box;
    position: relative;
    overflow: visible;
    display: inline-block;
    padding: 0.3em 0.8em;
    border: 1px solid $light-semi-gray;
    margin: 0;
    text-decoration: none;
    text-shadow: 1px 1px 0 $white;
    font-size: 11px;
    color: $theme-body-font-color !important;
    white-space: nowrap;
    cursor: pointer;
    outline: none;
    background-color: $white;
    background-image: -webkit-gradient(
        linear,
        0 0,
        0 100%,
        from($light-semi-gray),
        to($light-color)
    );
    background-image: -moz-linear-gradient($white, $white);
    background-image: -o-linear-gradient($white, $white);
    background-image: linear-gradient($white, $white);
    -webkit-background-clip: padding;
    -moz-background-clip: padding;
    -o-background-clip: padding-box;
    -webkit-border-radius: 0.2em;
    -moz-border-radius: 0.2em;
    border-radius: 0.2em;
    zoom: 1;
    display: inline;
    margin-top: 10px;
    &:hover {
        border-color: $light-semi-gray;
        text-decoration: none;
        box-shadow: none;
    }
    &:focus,
    &:active {
        background-image: -webkit-gradient(
            linear,
            0 0,
            0 100%,
            from($light-gray),
            to($light-semi-gray)
        );
        background-image: -moz-linear-gradient($light-gray, $light-semi-gray);
        background-image: -o-linear-gradient($light-gray, $light-semi-gray);
        background-image: linear-gradient($light-gray, $light-semi-gray);
    }
    &::-moz-focus-inner {
        padding: 0;
        border: 0;
    }
}
/* overrides extra padding on button elements in Firefox */
.introjs-skipbutton {
    box-sizing: content-box;
    margin-right: 5px;
    color: $primary-color;
}
.introjs-prevbutton {
    -webkit-border-radius: 0.2em 0 0 0.2em;
    -moz-border-radius: 0.2em 0 0 0.2em;
    border-radius: 0.2em 0 0 0.2em;
    border-right: none;
    &.introjs-fullbutton {
        border: 1px solid $light-semi-gray;
        -webkit-border-radius: 0.2em;
        -moz-border-radius: 0.2em;
        border-radius: 0.2em;
    }
}
.introjs-nextbutton {
    -webkit-border-radius: 0 0.2em 0.2em 0;
    -moz-border-radius: 0 0.2em 0.2em 0;
    border-radius: 0 0.2em 0.2em 0;
    &.introjs-fullbutton {
        -webkit-border-radius: 0.2em;
        -moz-border-radius: 0.2em;
        border-radius: 0.2em;
    }
}
.introjs-disabled {
    color: $primary-color;
    border-color: $light-semi-gray;
    box-shadow: none;
    cursor: default;
    background-image: none;
    text-decoration: none;
    &:hover,
    &:focus {
        color: $primary-color;
        border-color: $light-semi-gray;
        box-shadow: none;
        cursor: default;
        background-image: none;
        text-decoration: none;
    }
}
.introjs-hidden {
    display: none;
}
.introjs-bullets {
    text-align: center;
    ul {
        box-sizing: content-box;
        clear: both;
        margin: 15px auto 0;
        padding: 0;
        display: inline-block;
        li {
            box-sizing: content-box;
            list-style: none;
            float: left;
            margin: 0 2px;
            a {
                box-sizing: content-box;
                display: block;
                width: 6px;
                height: 6px;
                background: $light-gray;
                border-radius: 10px;
                -moz-border-radius: 10px;
                -webkit-border-radius: 10px;
                text-decoration: none;
                cursor: pointer;
                &:hover {
                    background: $white;
                }
                &.active {
                    background: $white;
                }
            }
        }
    }
}

.introjs-progress {
    box-sizing: content-box;
    overflow: hidden;
    height: 10px;
    margin: 10px 0 5px 0;
    border-radius: 4px;
    background-color: $light-color;
}
.introjs-progressbar {
    box-sizing: content-box;
    float: left;
    width: 0;
    height: 100%;
    font-size: 10px;
    line-height: 10px;
    text-align: center;
    background-color: $info-color;
}
.introjsFloatingElement {
    position: absolute;
    height: 0;
    width: 0;
    left: 50%;
    top: 50%;
}
.introjs-fixedTooltip {
    position: fixed;
}
.introjs-hint {
    box-sizing: content-box;
    position: absolute;
    background: transparent;
    width: 20px;
    height: 15px;
    cursor: pointer;
    &:focus {
        border: 0;
        outline: 0;
    }
}

.introjs-hidehint {
    display: none;
}

.introjs-fixedhint {
    position: fixed;
}

.introjs-hint:hover > .introjs-hint-pulse {
    border: 5px solid rgba(60, 60, 60, 0.57);
}

.introjs-hint-pulse {
    box-sizing: content-box;
    width: 10px;
    height: 10px;
    border: 5px solid rgba(60, 60, 60, 0.27);
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    border-radius: 30px;
    background-color: rgba(136, 136, 136, 0.24);
    z-index: 10;
    position: absolute;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
}

.introjs-hint-no-anim .introjs-hint-dot {
    -webkit-animation: none;
    -moz-animation: none;
    animation: none;
}

.introjs-hint-dot {
    box-sizing: content-box;
    border: 10px solid rgba(146, 146, 146, 0.36);
    background: transparent;
    -webkit-border-radius: 60px;
    -moz-border-radius: 60px;
    border-radius: 60px;
    height: 50px;
    width: 50px;
    -webkit-animation: introjspulse 3s ease-out;
    -moz-animation: introjspulse 3s ease-out;
    animation: introjspulse 3s ease-out;
    -webkit-animation-iteration-count: infinite;
    -moz-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    position: absolute;
    top: -25px;
    left: -25px;
    z-index: 1;
    opacity: 0;
}

@-webkit-keyframes introjspulse {
    0% {
        -webkit-transform: scale(0);
        opacity: 0;
    }

    25% {
        -webkit-transform: scale(0);
        opacity: 0.1;
    }

    50% {
        -webkit-transform: scale(0.1);
        opacity: 0.3;
    }

    75% {
        -webkit-transform: scale(0.5);
        opacity: 0.5;
    }

    100% {
        -webkit-transform: scale(1);
        opacity: 0;
    }
}

@-moz-keyframes introjspulse {
    0% {
        -moz-transform: scale(0);
        opacity: 0;
    }

    25% {
        -moz-transform: scale(0);
        opacity: 0.1;
    }

    50% {
        -moz-transform: scale(0.1);
        opacity: 0.3;
    }

    75% {
        -moz-transform: scale(0.5);
        opacity: 0.5;
    }

    100% {
        -moz-transform: scale(1);
        opacity: 0;
    }
}

@keyframes introjspulse {
    0% {
        transform: scale(0);
        opacity: 0;
    }

    25% {
        transform: scale(0);
        opacity: 0.1;
    }

    50% {
        transform: scale(0.1);
        opacity: 0.3;
    }

    75% {
        transform: scale(0.5);
        opacity: 0.5;
    }

    100% {
        transform: scale(1);
        opacity: 0;
    }
}
/**=====================
    54. Tour  CSS Ends
==========================**/
